
// function initSlider(){
// 		$('.slider').slick({
// 			dots: true,
// 		  infinite: true,
// 		  speed: 500,
// 		  fade: true,
// 		  autoplay: true,
// 		  // cssEase: 'linear'
// 			prevArrow: '<div class="slick-prev"><i class="fa fa-chevron-left"></i></div>',
// 			nextArrow: '<div class="slick-next"><i class="fa fa-chevron-right"></i></div>'
// 		});
// 	}

// $('.fadeInLeft').hover(
//     function(){ $(this).addClass('swing'); },
//     function(){ $(this).removeClass('swing'); }
// );



$(document).ready(function(){
	$('.mainpage__slider').slick({
	  dots: true,
	  arrows: true,
	  infinite: true,
	  speed: 500,
	  pauseOnHover: true,
	  fade: true,
	  autoplay: true,
	  // cssEase: 'linear',
		prevArrow: '<div class="slick-prev"><i class="fa fa-chevron-left"></i></div>',
		nextArrow: '<div class="slick-next"><i class="fa fa-chevron-right"></i></div>'
	});
});


// function initSlider(slider, options) {
//     if ($.fn.slick) {
//         $(slider).slick(options);
//     } else {
//         setTimeout(function() {
//             initSlider(slider, options);
//         }, 500);
//     }
// }

// $(document).ready(function(){
//   $('.slider').slick({});
// });

WOW.prototype.addBox = function(fadeInRight){
    this.boxes.push(fadeInRight);
};

$('.wow').on('scrollSpy:exit',function(){
    var fadeInRight = $(this);
    fadeInRight.css({
        'visibility' : 'hidden',
        'animation-name' : 'none'
    }).removeClass('animated');
    wow.addBox(this);
});




// ----------добавление удаление класса на время
// (function($){

//     $.fn.extend({ 

//         addTemporaryClass: function(className, duration) {
//             var elements = this;
//             setTimeout(function() {
//                 elements.removeClass(className);
//             }, duration);

//             return this.each(function() {
//                 $(this).addClass(className);
//             });
//         }
//     });

// })(jQuery);


// $(".sport").addTemporaryClass("animation-target", 10000);
// ---------------------------

// $('.sport').hover(
//     function(){ $(this).addClass('animation-target'); }
//     // function(){ $(this).removeClass('animation-target'); }
// );


// -----------оригинал
// function rainbow() {
//   $('.navigation li').each(function() {
//     $(this).addClass('selected');
//     setTimeout (function() {
//       $(this).removeClass('selected');
//     }, 9000);
//   });
// }


// _________Работающий вариант
// $('.scot').hover(
//     function(){ $(".scot").addTemporaryClass("animation-target", 6000); }
//     // function(){ $(this).removeClass('animation-target'); }
// );

// ------------оригинал
// $('.slick-slider').on('afterChange', function(event, slick, currentSlide){
//   if (currentSlide == 5) { console.log('Осуществлён переход к 5му слайду'); }
// });


// // -------------не сработали
// $('#slick-slider').on('afterChange', function(event, slick, currentSlide){
//   if (currentSlide == 2) { $("#scot").addTemporaryClass("animation-target", 6000);  }
// });


// $('#slick-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
//   console.log('Собираемся осуществить переход к '+nextSlide+' слайду');
// });
// // --------------------------

$('.mainpage__product').slick({
      infinite: true,
      dots: false,
      speed: 2000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      draggable: true,
      arrows: true,
      responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        }
    }, 
    {
      breakpoint: 730,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        }
    }]
    
});



$(".comparison").mouseenter(function(){
    $('.triangler').addClass('rubberBand')
    }).mouseleave(function(){
    $('.triangler').removeClass('rubberBand')
    });

$('.tip_as').tooltipster({
   delay: 0,
   // theme: 'tooltipster-punk',
   maxWidth: '100',
   repositionOnScroll: 'true',
   // side: 'top',
   updateAnimation: 'scale',
   interactive: 'true',
   theme: ['tooltipster-noir', 'tooltipster-noir-sidebar_left'],
   trigger: 'hover'
});

// первый рабочи вариант меню
$('.tip_menu').tooltipster({
   delay: 0,
   // maxWidth: '500',
   repositionOnScroll: 'true',
   side: 'bottom',
   updateAnimation: 'scale',
   interactive: 'true',
   delay: [0, 300],
   delayTouch: [0, 300],
   minIntersection: 5,
   distance: -7,
   theme: ['tooltipster-shadow', 'tooltipster-shadow-menu'],
   trigger: 'hover'
});

// второй преобразованный вариант меню - не подошло
// $('body').on('mouseover mouseenter', '.tip_menu', function(){
//          $(this).tooltipster({
//            delay: 0,
//            repositionOnScroll: 'true',
//            side: 'bottom',
//            updateAnimation: 'scale',
//            interactive: 'true',
//            delay: [0, 300],
//            distance: -7,
//            theme: ['tooltipster-shadow', 'tooltipster-shadow-menu'],
//            trigger: 'click'
//          });
//          $(this).tooltipster('show');
//     });



// добавить-удалить класс при изменении размера окна
$(document).ready(function() {
  function checkWidth() {
    var windowWidth = $('body').innerWidth(),
        elem = $(".mainpage__body"); // лучше сохранять объект в переменную 
                                    
    if(windowWidth < 580){
      elem.removeClass('col-xs-9');
      elem.addClass('col-xs-12');
    }
    else{
      elem.removeClass('col-xs-12');
      elem.addClass('col-xs-9');
    }
  }

  checkWidth(); // проверит при загрузке страницы

  $(window).resize(function(){
    checkWidth(); // проверит при изменении размера окна клиента
  });
});


$(document).ready(function() {
  function checkWidth() {
    var windowWidth = $('body').innerWidth(),
        elem = $(".footer__col_wrap "); // лучше сохранять объект в переменную 
                                    
    if(windowWidth < 580){
      elem.removeClass('col-xs-8');
      elem.addClass('footer__col_xs');
    }
    else{
      elem.removeClass('footer__col_xs');
      elem.addClass('col-xs-8');
    }
  }

  checkWidth(); // проверит при загрузке страницы

  $(window).resize(function(){
    checkWidth(); // проверит при изменении размера окна клиента
  });
});


$(document).ready(function() {
  function checkWidth() {
    var windowWidth = $('body').innerWidth(),
        elem = $(".logo"); // лучше сохранять объект в переменную 
                                    
    if(windowWidth < 500){
      elem.removeClass('col-xs-4');
      elem.addClass('logo_xs');
    }
    else{
      elem.removeClass('logo_xs');
      elem.addClass('col-xs-4');
    }
  }

  checkWidth(); // проверит при загрузке страницы

  $(window).resize(function(){
    checkWidth(); // проверит при изменении размера окна клиента
  });
});


$(document).ready(function() {
  function checkWidth() {
    var windowWidth = $('body').innerWidth(),
        elem = $(".rightbar"); // лучше сохранять объект в переменную 
                                    
    if(windowWidth < 500){
      elem.removeClass('col-xs-4');
      elem.addClass('rightbar_xs');
    }
    else{
      elem.removeClass('rightbar_xs');
      elem.addClass('col-xs-4');
    }
  }

  checkWidth(); // проверит при загрузке страницы

  $(window).resize(function(){
    checkWidth(); // проверит при изменении размера окна клиента
  });
});

$('.mainpage__product').slick({

});


